import { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SearcherInput from "../General/SearcherInput";
import {  getAdvertisers,
          getCampaigns,
          getMarkets,
          getInventories,
          getAdvertiserSaas,
          getClient,
          getCampaignSaas,
} from '../../services/reportsService';
import billableOptions from "./constants/billingOptionsList";
import Button from "../General/Button";
import i18n from "../../i18n";

const ModalFiltersReports = (props) => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    data !== null &&
    setFilteredData(
        data.filter(item =>
        (item.name ?? '').toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, data]);

  const handleSearchFilter = (e) => {
    setSearchTerm(e);
  };

  const fetchData = async (dataFunction) => {
    try {
      const result = await dataFunction;
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
  if (props.show === true) {
    switch (props.title) {
      case i18n.advanceReportsAdvertiser:
        fetchData(getAdvertisers());
        break;
      case i18n.advanceReportsCampaign:
        fetchData(getCampaigns(props.selectedAdvertisers));
        break;
      case i18n.advanceReportsBillingStatus:
        setData(billableOptions);
        break;
      case i18n.advanceReportsMarket:
        fetchData(getMarkets());
        break;
      case i18n.advanceReportsInventoryType:
        fetchData(getInventories());
        break;

        // Saas
      case i18n.advanceReportsAdvertiserSaas:
        fetchData(getAdvertiserSaas());
        break;
      case i18n.advanceReportsClient:
        fetchData(getClient(props.selectedAdvertiserSaas));
        break;
      case i18n.advanceReportsCampaignSaas:
        fetchData(getCampaignSaas(props.selectedAdvertiserSaas, props.selectedClients));
        break;
      
      default:
        break;
    }
  }
  }, [props.show]);

  const handleSelectDeselectAll = () => {
    if (props.selectedItems.length === filteredData.length) {
      props.setSelectedItems([]);
    } else {
      props.setSelectedItems([...filteredData]);
    }
  }

  const handleCheckboxChange = (item) => {
    if (props.selectedItems.some(selectedItem => selectedItem.id === item.id)) {
      props.setSelectedItems(props.selectedItems.filter(selectedItem => selectedItem.id !== item.id));
    } else {
      props.setSelectedItems([...props.selectedItems, item]);
    }
  };

  const handleApply = () => {
    props.setApplyButton(true);
    setSearchTerm('');
    props.handlePerformanceReport();
    props.handleClose();
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setSearchTerm("");
        props.handleClose();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
        <div className="divModalFilterReportsTitle">
          <div className="divModalFilterReportsTitleSearcher">
            <SearcherInput
              iconSize={18}
              onChange={handleSearchFilter}
              placeholder="Search..."
              divStyle="searchDivGrey"
              inputStyle="searchInputGrey"
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modalFilterSelectAll">
          <button
            className="buttonFilters buttonFiltersVariablesSmaller"
            onClick={handleSelectDeselectAll}>{filteredData==null || props.selectedItems.length !== filteredData.length ? 'Select all' : 'Deselect all'}
          </button>
        </div>
        <Row className="rowModalFilter">
          <Col xs={6} className="colModalFilter">
            {
              filteredData &&
                filteredData.map((item) => (
                  <div key={item.id} className="divListAttribute lettersPerformanceReport">
                    <input
                      key={item.id}
                      type="checkbox"
                      value={item.id}
                      checked={props.selectedItems.some(selectedItem => selectedItem.id === item.id)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    {item.name} {item.country && ' - ' + item.country}
                  </div>
                ))
            }
          </Col>
          <Col xs={6} className="colModalFilter">
            <h3 className="lettersPerformanceReport fontBold modalFiltersselectedItems">Selected</h3>
            {
              props.selectedItems &&
                props.selectedItems.map((item) => (
                  <div className="divListAttribute lettersPerformanceReport">
                    {item.name}
                  </div>
                ))
            }
          </Col>
        </Row>
        <div className="divDateRangeButton">
          <h3 onClick={props.handleClose} className="h3Cancel">{i18n.cancel}</h3>
          <Button text="Apply" className="largeSizeButton" onClick={handleApply} />
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default ModalFiltersReports; 