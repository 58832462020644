import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

const SortableHeader = ({ field, label, sortConfig, onSort, headerLarge }) => {
  const isActive = sortConfig.key === field;
  const icon = sortConfig.direction === 'asc' ?
      <>
        {' '}
        <TiArrowSortedUp />
      </>
    : <>
        {' '}
        <TiArrowSortedDown />
      </>;

  return (
    <th className={`thPerformanceReport ${headerLarge ? 'thPerformanceReportLarge' : ''}`} onClick={() => onSort(field)}>
      {label}
      {icon && <span className="sort-icon">{icon}</span>}
    </th>
  );
};

export default SortableHeader;
