import i18n from "../../../i18n"; 
 /* allowToWatch represent role id :
 0 - all
 1 - advertiser client
 2 - specific client from one advertiser */
 const filterReportsSaasList = [
  {allowToWatch : 1, title: i18n.advanceReportsAdvertiserSaas, value: i18n.advanceReportsAdvertiserSaas},
  {allowToWatch : 1, title: i18n.advanceReportsClient, value: i18n.advanceReportsClient},
  {allowToWatch : 2, title: i18n.advanceReportsClient, value: i18n.advanceReportsClient},
  {allowToWatch : 0, title: i18n.advanceReportsCampaign, value: i18n.advanceReportsCampaignSaas},
  {allowToWatch : 0, title: i18n.advanceReportsInventoryType, value: i18n.advanceReportsInventoryType},
]

export default filterReportsSaasList;